<template>
  <div>
    <!-- If there is already an image saved -->
    <template v-if="item.Value.Name">
      <div :class="needsDisable ? '' : 'imageContainer'">
        <img
          class="pageImage"
          :src="
            serverUrl +
            '/images/' +
            websiteId +
            '/' +
            item.Value.Name +
            '?token=' +
            userToken
          "
        />
        <v-layout row wrap class="editContainer mt-2 mt-2 pl-3 pr-3">
          <template v-if="(!item.ReadOnly && !needsDisable) || editItemAdmin">
            <template v-if="item.AddAltTags || item.AddAltTags == null">
              <template v-if="item.Translate || item.Translate == undefined">
                <v-flex lg3 sm3 xs12 pr-4>
                  <v-select
                    :items="itemLanguages"
                    v-model="itemDefaultLanguage"
                    single-line
                    class="altSelect"
                  ></v-select>
                </v-flex>
              </template>
              <v-flex lg7 sm7 xs10 pr-4>
                <template
                  v-for="(translatedValue, translatedValueIndex) in item.Value
                    .Alt"
                >
                  <template v-if="itemDefaultLanguage == translatedValueIndex">
                    <input
                      :key="translatedValueIndex"
                      type="text"
                      class="textInput"
                      placeholder="Alt tag"
                      v-model="item.Value.Alt[translatedValueIndex]"
                    />
                  </template>
                </template>
              </v-flex>
            </template>
            <v-flex lg2 sm2 xs2>
              <v-btn
                color="error"
                class="customRemoveButton"
                icon
                @click="deleteImage(item)"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-flex>
          </template>
        </v-layout>
      </div>
    </template>
    <!-- If no image was saved -->
    <template v-else-if="(!item.ReadOnly && !needsDisable) || editItemAdmin">
      <h3 v-if="file">
        <v-icon>mdi-image</v-icon>
        {{ file.name }}
        <v-btn color="error" icon @click="deleteSelectedImage()">
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </h3>
      <input
        :ref="'fileInput' + objectName"
        class="fileInput"
        type="file"
        @change="setSelectedFile($event.target.files)"
      />

      <v-btn
        class="ml-0 mt-3 mr-3 edit-page-action-button"
        @click="$refs['fileInput' + objectName].click()"
        color="primary"
        >{{ $t("Choose an image") }}
      </v-btn>
      <v-btn
        color="blue-grey"
        class="ml-0 mt-3 white--text edit-page-action-button"
        :disabled="!file || uploading || uploadError"
        :loading="uploading"
        @click="submitFile(item)"
      >
        {{ $t("Upload") }}
        <v-icon right dark>mdi-cloud-upload</v-icon>
      </v-btn>
    </template>

    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />
      <v-row no-gutters class="my-5">
        <v-col cols="12">
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              dense
              :label="$t('Column width')"
              class="mw-w"
            ></v-text-field>

            <v-switch
              v-model="item.ReadOnly"
              hide-details="auto"
              class="float-left mr-3"
              :label="$t('Read-only')"
            ></v-switch>
            <v-switch
              v-model="item.FieldValidation"
              @change="$helpers.changeFieldValidation(item)"
              class="float-left mr-3"
              :label="$t('Field validation')"
            >
            </v-switch>
            <v-combobox
              v-if="item.FieldValidation"
              :items="$validation.getAllValidationNames()"
              class="float-left mr-3"
              :label="$t('Rule')"
              v-model="item.FieldValidationFuntion"
              clearable
              @click:clear="item.FieldValidationFuntion = ''"
            >
            </v-combobox>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    websiteId: {
      type: String,
    },
    pageId: {
      type: String,
    },
    objectName: {
      type: [Array, Object, String],
    },
    uploadError: {},
    editItemAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      userToken: this.$auth.userToken(),
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
      serverUrl: this.$helpers.getServerUrl(),
      uploading: false,
      file: null,
    };
  },
  methods: {
    getItemDefaultLanguage() {
      return this.$config.contentLanguages[0];
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
    deleteImage(item) {
      item.Value.Name = "";
    },
    deleteSelectedImage() {
      this.file = null;
    },
    setSelectedFile(fileList) {
      this.$emit("setUploadError", "");
      this.file = fileList[0];
    },
    submitFile(item) {
      this.uploading = true;
      this.$emit("update:saving", true);
      this.$emit("setUploadError", "");

      let formData = new FormData();
      formData.append("files", this.file);
      this.$request.post(
        "/content/imageupload/" + this.pageId,
        formData,
        (res) => {
          this.uploading = false;
          this.$emit("update:saving", false);
          this.$refs["fileInput" + this.objectName].value = null;
          if (res.success) {
            if (item.Value == "") {
              item.Value = {
                Name: "",
                Alt: {
                  Nl: "",
                  Fr: "",
                  En: "",
                },
              };
            }

            item.Value.Name = res.data[0];
            this.file = null;
          } else {
            this.$emit("setUploadError", res.message);
          }
        }
      );
    },
  },
};
</script>

<style scoped>
.fileInput {
  display: none;
}
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
.imageContainer {
  position: relative;
  display: table;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  cursor: pointer;
}

.imageContainer img {
  opacity: 1;
  margin-bottom: -6px;
}

.imageContainer:hover img,
.imageContainer:focus img {
  opacity: 0.6;
}

.imageContainer .editContainer {
  background-color: #fff;
  display: none;
  width: 100%;
}

.imageContainer .editContainer .textInput {
  margin: 18px 0;
}

.imageContainer .editContainer .customRemoveButton {
  margin: 14px 0;
}

.imageContainer:hover .editContainer,
.imageContainer:focus .editContainer {
  display: flex;
}

.pageImage {
  max-width: 100%;
  max-height: 500px;
}
@media only screen and (max-width: 568px) {
  .edit-page-action-button {
    width: 100%;
  }

  .edit-page-action-button .btn__content {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 8px;
  }

  .edit-page-action-button .btn__content i {
    padding-top: 0px;
    margin-top: -3px;
    margin-left: 5px;
  }
}
</style>